import "../components/Products/Products.css"
import HraContact from "../components/Hra/HraContact"
import PriceComparison from "../components/Products/PriceComparison"
import ResourceCenter from "../components/Resource-Center/ResourceCenter"
import ResourceCenterVideo from "../components/Resource-Center/ResourceCenterVideo"
import { toSalusionPrivate } from "../util"
import { Link } from "react-router-dom"

import ichravsqshera from "../assets/ichravsqsehra-poster.svg"
import guideIchra from "../assets/guide-to-ichra.svg"
import employees from "../assets/50-employees.svg"
// import reimbursement from "../assets/reimbursement-options.svg"
import ichraCalculator from "../assets/ichra-calculator.svg"

import { PRICING_DATA } from "../components/Products/data"
import { TABLE_DATA } from "../components/Products/data"
import { Helmet } from "react-helmet-async"

export default function IchraPricing() {
    const schema = {
        "@context": "https://schema.org",
        "@type": "ItemList",
        "itemListElement": [
            {
                "@type": "Product",
                "name": "ICHRA Price Comparison",
                "description": "Salusion’s ICHRA offers the best-in-class features at the lowest prices.",
                "image": "https://salusion.com/assets/images/homepage/reimbursement.svg",
                "brand": {
                    "@type": "Brand",
                    "name": "Salusion"
                },
                "offers": {
                    "@type": "Offer",
                    "price": "14.00",
                    "priceCurrency": "USD",
                    "availability": "https://schema.org/InStock",
                    "priceSpecification": {
                        "@type": "UnitPriceSpecification",
                        "price": "14.00",
                        "priceCurrency": "USD",
                        "billingDuration": 1,
                        "billingIncrement": 1,
                        "unitText": "participant/month",
                        "billingPeriod": "http://purl.org/goodrelations/v1#Month",
                    },
                    "eligibleQuantity": {
                        "@type": "QuantitativeValue",
                        "value": 1,
                        "unitText": "participant"
                    },
                    "shippingDetails": {
                        "@type": "OfferShippingDetails",
                        "shippingRate": {
                            "@type": "MonetaryAmount",
                            "value": "0.00",
                            "currency": "USD"
                        },
                        "shippingDestination": {
                            "@type": "DefinedRegion",
                            "name": "United States"
                        },
                        "deliveryTime": {
                            "@type": "QuantitativeValue",
                            "value": "0",
                            "unitCode": "DAY"
                        }
                    }
                },
            },
            {
                "@type": "Product",
                "name": "Salusion ICHRA",
                "description": "Salusion’s ICHRA offers the best-in-class features at the lowest prices.",
                "brand": {
                    "@type": "Brand",
                    "name": "Take Command"
                },
                "offers": {
                    "@type": "Offer",
                    "priceCurrency": "USD",
                    "availability": "https://schema.org/InStock",
                    "priceSpecification": [
                        {
                            "@type": "UnitPriceSpecification",
                            "price": "20.00",
                            "priceCurrency": "USD",
                            "unitText": "person/month",
                            "billingDuration": "1",
                            "billingPeriod": "http://purl.org/goodrelations/v1#Month"
                        },
                        {
                            "@type": "PriceSpecification",
                            "price": "40.00",
                            "priceCurrency": "USD",
                            "description": "Platform fee",
                            "billingDuration": "1",
                            "billingPeriod": "http://purl.org/goodrelations/v1#Month"
                        }
                    ],
                },
            }, {
                "@type": "Product",
                "name": "Salusion ICHRA",
                "description": "Salusion’s ICHRA offers the best-in-class features at the lowest prices.",
                "brand": {
                    "@type": "Brand",
                    "name": "PeopleKeep"
                },
                "offers": {
                    "@type": "Offer",
                    "priceCurrency": "USD",
                    "availability": "https://schema.org/InStock",
                    "priceSpecification": [
                        {
                            "@type": "UnitPriceSpecification",
                            "price": "19.00",
                            "priceCurrency": "USD",
                            "unitText": "person/month",
                            "billingDuration": "1",
                            "billingPeriod": "http://purl.org/goodrelations/v1#Month"
                        },
                        {
                            "@type": "PriceSpecification",
                            "price": "35.00",
                            "priceCurrency": "USD",
                            "description": "Platform fee",
                            "billingDuration": "1",
                            "billingPeriod": "http://purl.org/goodrelations/v1#Month"
                        },
                        {
                            "@type": "PriceSpecification",
                            "price": "150.00",
                            "priceCurrency": "USD",
                            "description": "One-time setup fee",
                            "billingDuration": "1",
                            "billingPeriod": "http://purl.org/goodrelations/v1#BillingPeriodUnspecified"
                        }
                    ],
                },
            },

        ]
    }
    function scrollToContact() {
        document.getElementById("start_hra").scrollIntoView({ behavior: "smooth" })
    }

    return (
        <>
            <Helmet>
                <title>Salusion - ICHRA Pricing</title>
                <meta name="title" content="Compare ICHRA Pricing: Best Features at the Lowest Prices | Salusion" />
                <meta name="description" content="Discover why Salusion’s ICHRA offers the best-in-class features at the lowest prices. Compare our pricing and features to see how Salusion provides the most cost-effective solution for your business." />
                <script type="application/ld+json">{JSON.stringify(schema)}</script>
            </Helmet>

            <section id="gsehra_rules" className="first-section-full-background first-padding rules-all">
                <div className="container">
                    <div className="salusion-guide-wrapper">
                        <h1>ICHRA Price Comparison</h1>
                        <p>Salusion’s ICHRA offers the best-in-class features at the lowest prices.  Compare our pricing and discover why Salusion is the most cost-effective solution for administering your ICHRA.</p>
                        <div className="salusion-guide-btn-wrapper">
                            <Link onClick={(event) => toSalusionPrivate(event, "/create-account", false)}>
                                Start Your Company's HRA
                            </Link>

                            <Link onClick={scrollToContact}>Speak with an Expert</Link>
                        </div>
                        <br />
                        <br />
                        <div className="products-hra-wrapper">
                            <PriceComparison dataPricing={PRICING_DATA} dataCheckmark={TABLE_DATA} title="ICHRA Price Comparison" />
                        </div>
                    </div>
                </div>

            </section>

            <ResourceCenter>
                <ResourceCenterVideo
                    resourceImg={guideIchra}
                    title="A flexible, low-cost alternative to group coverage"
                    pageLink="/product/ichra"
                />

                <ResourceCenterVideo
                    resourceImg={ichravsqshera}
                    title="Discover which HRA best fits your small business"
                    pageLink="/product/ichra-vs-qsehra"
                />

                <ResourceCenterVideo
                    resourceImg={employees}
                    title="ICHRA topics for large employers"
                    pageLink="/product/ichra/large-business"
                />

                <ResourceCenterVideo
                    resourceImg={ichraCalculator}
                    title="Discover how much you can save with an ACA-compliant ICHRA"
                    e
                    xternalLink="product/ichra/aca-compliant-calculator"
                />

                {/* <ResourceCenterVideo
                    resourceImg={reimbursement}
                    title="Discussion of the pros and cons of each reimbursement option"
                    pageLink="/product/ichra/reimbursement-options"
                /> */}


            </ResourceCenter>
            <div className="products-hra-wrapper">
                <HraContact />
            </div>
        </>
    )
}