import { Link } from "react-router-dom"
import "../styles.css"
import { Helmet } from "react-helmet-async"
import ichravsqshera from "../../../assets/ichravsqsehra-poster.svg"
import ichraRules from "../../../assets/ichraRules.svg"
import employees from "../../../assets/50-employees.svg"
import ichraCalculator from "../../../assets/ichra-calculator.svg"
// import reimbursement from "../../../assets/reimbursement-options.svg"
import guideIchra from "../../../assets/guide-to-ichra.svg"
import ResourceCenter from "../../../components/Resource-Center/ResourceCenter"
import ResourceCenterVideo from "../../../components/Resource-Center/ResourceCenterVideo"

export const question = "What is the definition of a part-time employee for an ICHRA and a QSEHRA?"

export default function LearningEntry() {
	const schema = {
		"@context": "https://schema.org",
		"@type": "FAQPage",
		"mainEntity": [
			{
				"@type": "Question",
				"name": "What is the definition of a part-time employee for an ICHRA and a QSEHRA?",
				"acceptedAnswer": {
					"@type": "Answer",
					"text": "Part-time employees are defined as employees who are not full-time under the Code §4980H regulations or who are part-time under the Code §105 regulations, as elected by the HRA sponsor.",
					"dateCreated": "2024-10-05T14:30:00+00:00",
				},
				"datePublished": "2024-10-05T14:30:00+00:00",
				"dateModified": "2024-10-05T14:30:00+00:00",
			},
		]
	}
	return (
		<>
			<Helmet>
				<title>Salusion - Employer Resources</title>
				<meta name="title" content="FAQ: What is the definition of a part-time employee for an ICHRA and a QSEHRA?" />
				<meta name="description" content="Part-time employees are defined as employees who are not full-time under the Code §4980H regulations or who are part-time under the Code §105 regulations, as elected by the HRA sponsor." />
				<script type="application/ld+json">{JSON.stringify(schema)}</script>
			</Helmet>

			<section className="first-section-full-background first-padding rules-all">
				<div className="container">
					<h1>Learning Center</h1>

					<div className="entry-container white-wrapper-all">
						<h3>{question}</h3>
						<hr className="divider" />

						<p className="text">
							Part-time employees are defined as employees who are not full-time under the Code §4980H regulations or
							who are part-time under the <a href="https://www.law.cornell.edu/cfr/text/26/1.105-11">Code §105</a> regulations, as elected by the HRA sponsor.
						</p>

						<ul>
							<li>Using Code §4980H, a part-time employee works less than 30-hours a week on average.</li>

							<li>
								Using <a href="https://www.law.cornell.edu/cfr/text/26/1.105-11">Code §105</a>, employees whose customary weekly employment is less than 35 hours, if other employees
								in similar work with the same employer (or, if no employees of the employer are in similar work, in
								similar work in the same industry and location) have substantially more hours. Notwithstanding the
								preceding sentence, any employee whose customary weekly employment is less than 25 hours be considered
								part-time.
							</li>
						</ul>

						<Link to="/learning-center" className="link">
							<i className="back-icon bi bi-arrow-left" />
							Back To Learning Center
						</Link>
					</div>
				</div>
			</section>
			<ResourceCenter>
				<ResourceCenterVideo
					resourceImg={guideIchra}
					title="A flexible, low-cost alternative to group coverage"
					pageLink="/product/ichra/video-guide"
				/>
				<ResourceCenterVideo
					resourceImg={ichraRules}
					title="Take a deep dive into the rules and regulations that govern ICHRAs"
					pageLink="/product/ichra/rules-and-regulations"
				/>

				<ResourceCenterVideo
					resourceImg={ichravsqshera}
					title="Discover which HRA best fits your small business"
					pageLink="/product/ichra-vs-qsehra"
				/>

				<ResourceCenterVideo
					resourceImg={employees}
					title="ICHRA topics for large employers"
					pageLink="/product/ichra/large-business"
				/>

				<ResourceCenterVideo
					resourceImg={ichraCalculator}
					title="Discover how much you can save with an ACA-compliant ICHRA"
					e
					xternalLink="product/ichra/aca-compliant-calculator"
				/>

				{/* <ResourceCenterVideo
					resourceImg={reimbursement}
					title="Discussion of the pros and cons of each reimbursement option"
					pageLink="/product/ichra/reimbursement-options"
				/> */}


			</ResourceCenter>
		</>
	)
}
